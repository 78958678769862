import styled from 'styled-components';

const Bullet = styled.div`
  width: 20px;
  height: 20px;
  background: var(--timeline-bullet-color);
  border-radius: 50%;
  position: absolute;
  left: 50%;
  margin-top: 25px;
  left: 49.3%;
`;

export default Bullet;
