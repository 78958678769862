/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';

import RichBody from '../article/RichBody';

const Container = styled.div`
  position: relative;
  width: 40%;
  padding: 10px 30px;
  border-radius: 6px;
  background: #f9f9f9;
  // box-shadow: 0 20px 25px -15px rgba(0, 0, 0, 0.3);
  // transform: translateX(${props => props.isOdd ? '100px' : '690px'}) scale(1);
  white-space: pre-wrap;

  /* Small only */
  @media screen and (max-width: 39.9375em) {
    transform: translateX(0) scale(1);
    width: 100%;
    padding: 10px;
  }

  /* Medium and up */
  @media screen and (min-width: 40em) {}

  /* Medium only */
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    width: 80%;
  }

  /* Large and up */
  @media screen and (min-width: 64em) {
      width: 60%;
  }

  /* Large only */
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {}
`;

const BlockHeader = styled.div`
  margin-bottom: 10px;
`;

const Datetime = styled.div`
  color: var(--article-datetime);
`;

// const Title = styled.div`
//   font-weight: 700;
// `;

// const Job = styled.div`
// `;

const TimelineContent = props => {
  // const splitLocale = locale.substring(0, 2);
  // const date = props[`date-${splitLocale}`];
  // const content = props[`content-${splitLocale}`];
  const { isOdd, node: { title, description } } = props;
  return (
    <Container isOdd={isOdd}>
      <BlockHeader>
        <Datetime>{title}</Datetime>
        <div>
          <RichBody json={description.json} />
        </div>
      </BlockHeader>
    </Container>
  );
}

TimelineContent.propTypes = {
  isOdd: PropTypes.bool.isRequired,
};

export default TimelineContent;
