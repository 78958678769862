import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TimelineItem from '../../components/timeline/TimelineItem';

const BigContainer = styled.section`
  position: relative;
  width: 100%;
`;

const Container = styled.ul`
  &:before {
    content: '';
    background: var(--timeline-bullet-color);
    width: 2px;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  /* Small only */
  @media screen and (max-width: 39.9375em) {
    padding: 0px;
  }

  /* Medium and up */
  @media screen and (min-width: 40em) {}

  /* Medium only */
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {}

  /* Large and up */
  @media screen and (min-width: 64em) {}

  /* Large only */
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {}


  ul li {
    list-style-type: none;
    position: relative;
    width: 6px;
    margin: 0 auto;
    padding-top: 50px;
    background: #fff;
  }

  ul li::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: inherit;
  }
`;

const Timeline = ({ data }) => (
  <BigContainer>
    <Container>
      {
        data && data.map((item, idx) => (
          <TimelineItem
            key={item.id}
            isOdd={idx % 2 ? false : true} // eslint-disable-line
            {...item}
          />
        ))
      }
    </Container>
  </BigContainer>
);

Timeline.propTypes = {
  // intl: PropTypes.shape({
  //   locale: PropTypes.string,
  //   formatMessage: PropTypes.func,
  // }).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Timeline;
